export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 150
  },
  {
    title: '液位仪编号',
    key: 'meterNum',
    minWidth: 100
  },
  {
    title: '油罐号',
    key: 'tankNum'
  },
  {
    title: '异常内容',
    key: 'msg'
  },
  {
    title: '状态',
    key: 'status',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '报警时间',
    key: 'createTime'
  },
  {
    title: '恢复时间',
    key: 'recoveryTime'
  }
];

export const dataSocure = [
  {
    id: 1,
    siteName: '加油站2222',
    meterNum: '123',
    tankNum: '2',
    msg: '探针异常',
    status: 1,
    createTime: '2021-04-12 20:05:20',
    recoveryTime: '2021-04-12 23:05:20'
  },
  {
    id: 2,
    siteName: '加油站3223',
    meterNum: '12332',
    tankNum: '1',
    msg: '探针2异常',
    status: 0,
    createTime: '2021-04-12 20:05:20',
    recoveryTime: '2021-04-12 23:05:20'
  }
];
