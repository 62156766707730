<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <template v-slot:extra></template>
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.siteId"
            filterable
            :clearable="true"
            placeholder="加油站(可输入)"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.meterNum"
            clearable
            placeholder="液位仪编号"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :loading="tableLoading"
      @currentChange="handleCurrentChange"
      stripe
      :tableStatus="tableStatus"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #status="{ scope }">
        <span
          :class="scope.row.status === '0' ? 'status-danger' : 'status-info'"
        >
          {{ statusList[scope.row.status] }}</span
        >
      </template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import {
  QueryTankProbeError,
  ExportTankProbeError
} from '@/api/alarm_level_gauge';
import DefaultTable from '@/components/DefaultTable/new';
import { columns } from './columns/tank_probe_abno';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      searchParams: {},
      columns,
      tableStatus: 'recoveryTime',
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      tableLoading: false,
      date: '',
      statusList: ['未恢复', '已恢复']
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.tableLoading = true;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleSearch() {
      console.log('search');
    },
    handleQuery({ current, pageSize }) {
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryTankProbeError(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.tableLoading = false;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        }
      });
    },
    // 导出
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportTankProbeError(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    }
  }
};
</script>
<style scoped>
.status-danger {
  color: #f56c6c;
}
.status-info {
  color: #01adbe;
}
</style>
